@use "../config/" as *;
.site-header-wrap:has(.btn-search-toggle[aria-expanded="true"]),
.site-header-wrap:has(.btn-hamburger[aria-expanded="true"]) {
    --z-header: 13;
}
.site-header-wrap {
    top: 0;
    z-index: var(--z-header);
    position: fixed;
    width: 100%;
    .social-list {
        gap: 1.5rem;
        @extend %secondary-1000-bg;
        @extend %flex;
        @extend %w-100;
        @extend %p-3;
        .social-link {
            @extend %flex;
        }
        .social-anchor {
            @extend %font-0;
            @extend %pointer;
            &::before {
                content: "";
                line-height: 1;
                font-family: $font-icon;
                @extend %font-15-pr;
                @extend %flex-c-c;
            }
            &.social-anchor {
                @each $icon-key, $icon in $social {
                    &-#{$icon-key}::before {
                        content: $icon;
                    }
                }
            }
        }
    }
}
.site {
    &-header-layout {
        height: var(--header-height);
        @extend %secondary-1000-bg;
        @extend %neutral-50;
        @extend %flex-fe-c;
        @extend %flex-wrap;
        @extend %pt-2;
        &::before {
            // content: "";
            height: var(--window-inner-height);
            height: 100dvh;
            width: var(--window-inner-width);
            position: absolute;
            left: 0;
            top: 0;
        }
        .logo {
            width: var(--logo-width);
            height: var(--logo-height);
            @extend %relative;
            @extend %d-block;
            &-text {
                @include background(null, "kkr-logo.png", center / contain no-repeat);
                @extend %relative;
                @extend %w-100;
                @extend %h-100;
                @extend %m-0;
                @extend %font-0;
                @extend %flex-c-c;
            }
        }
    }
    &-logo {
        top: 0.7rem;
        left: 1.5rem;
        @extend %absolute;
    }
    &-search-section {
        @extend %mr-3;
        @extend %relative;
    }
    &-search {
        .btn-text {
            @extend %font-0;
            &::before {
                content: "\e81a";
                font: 1.5rem/1.8rem "waf-font-icon";
                @extend %neutral-50;
            }
        }
    }
    &-redirects {
        padding: var(--space-1) 0;
        @extend %secondary-900-bg;
        @extend %mb-2-neg;
        @extend %w-100;
    }
    &-login {
        @extend %mr-4;
        @extend %flex;
    }
}
.btn-search-toggle {
    @extend %pr-3;
}
[type="search"]::-webkit-search-cancel-button {
    appearance: none;
}
.site-search,
.redirect-link,
.btn-hamburger {
    &::after {
        content: "";
        height: 1.5rem;
        width: 0.1rem;
        opacity: 0.3;
        @extend %pos-y-center;
        @extend %neutral-50-bg-5;
        @extend %absolute;
    }
}
.btn-hamburger {
    &::after {
        left: -1.5rem;
    }
}
.redirect {
    &-nav {
        @extend %px-2;
    }
    &-nav-list {
        @extend %flex;
        @extend %h-100;
        @extend %pl-0;
    }
    &-list {
        @extend %px-3;
    }
    &-link {
        flex: 1;
        @extend %text-center;
        @extend %relative;
        &:last-child::after {
            @extend %d-none;
        }
        &:not(:last-child):after {
            content: "";
            right: 0;
        }
    }
    &-text {
        @extend %uppercase;
        @extend %font-11-pr;
    }
    &-anchor {
        white-space: nowrap;
        @each $icon-key, $icon in $redirect {
            &-#{$icon-key}::before {
                content: $icon;
                font: 1.4rem "waf-font-icon";
                @extend %neutral-50;
                @extend %mr-1;
            }
        }
    }
}
.site-login-wrap {
    @extend %pr-2;
    .sub-ul {
        width: 8.7rem;
        top: 6.2rem;
        right: 5rem;
        z-index: var(--z-index4);
        @extend %secondary-900-bg;
        @extend %pl-0;
        @extend %absolute;
        @extend %d-none;
        .nav-link,
        .nav-btn-token {
            line-height: inherit;
            @extend %text-left;
            @extend %w-100;
            @extend %py-1;
            @extend %px-2;
            @extend %d-block;
        }
        .nav-item {
            @extend %font-10;
            @extend %capitalize;
            @extend %relative;
            &::after {
                content: "";
                height: 0.01rem;
                opacity: 0.3;
                @extend %w-100;
                @extend %neutral-50-bg;
                @extend %pos-bl;
            }
            &:last-child:after {
                @extend %d-none;
            }
        }
    }
    .nav-btn {
        @extend %neutral-50;
        @extend %font-10;
        @extend %capitalize;
        &[aria-expanded="true"] {
            @extend %primary-500;
        }
    }
    .site-login {
        height: 2.2rem;
        width: 2.2rem;
        @extend %p-1;
        @extend %primary-500-bg;
        @extend %circle-radius;
        @extend %flex-c-c;
    }
    button.site-login {
        padding: 0;
        object-fit: contain;
    }
    .user-image {
        @extend %d-inline;
    }
}
.site-login[aria-expanded="true"] + .sub-ul {
    @extend %d-block;
}
.site-token {
    top: 6.2rem;
    left: 0;
    @extend %secondary-900-bg;
    @extend %w-100;
    @extend %p-3;
    @extend %neutral-50;
    @extend %absolute;
    @extend %d-none;
    .token-list {
        @extend %w-100;
        @extend %flex-sb-c;
    }
    .token-item {
        @extend %text-center;
    }
    .label {
        @extend %font-10;
        @extend %uppercase;
    }
    .value {
        padding: 0.4rem 2.5rem;
        margin-top: 0.2rem;
        border-radius: 10rem;
        @extend %font-14-pb;
        @extend %flex-c-c;
        @extend %primary-500;
        @extend %secondary-1000-bg;
    }
    .number {
        @extend %ml-2;
    }
    .icon {
        // @extend %flex-fs-c;
    }
    .icon::before {
        content: '';
        height: 1.5rem;
        width: 1.5rem;
        @extend %d-block;
    }
    .token::before {
        @include background(null, "icon/balance-token.png", bottom/contain no-repeat);
    }
    .balance-token:before {
        @include background(null, "icon/token.png", bottom/contain no-repeat);
    }
    .reward:before {
        @include background(null, "icon/reward.png", bottom/contain no-repeat);
    }
}
.show-token {
    opacity: 0;
    @extend %pointer-none;
    & + .site-token {
        z-index: var(--z-header);
        @extend %flex;
    }
}
.site-nav {
    width: var(--window-inner-width);
    height: calc(var(--window-inner-height) - (var(--header-height) + env(safe-area-inset-top) + env(safe-area-inset-bottom)));
    height: calc(100dvh - var(--header-height));
    top: var(--header-height);
    right: -100%;
    transition: right 500ms ease-in-out;
    @extend %fixed;
    @extend %neutral-50-bg;
    .btn-close {
        @extend %d-none;
    }
    .nav-anchor {
        @extend %w-100;
        @extend %text-left;
    }
    .nav-link,
    .sub-ul-link {
        @extend %relative;
        &::after {
            content: "";
            height: 0.1rem;
            opacity: 0.1;
            @extend %secondary-1000-bg;
            @extend %w-100;
            @extend %pos-bl;
        }
        &:last-child::after {
            @extend %d-none;
        }
    }
    .nav-link {
        button.nav-anchor {
            align-items: center;
            @extend %flex;
            &::after {
                content: "\e806";
                font-family: $font-icon;
                rotate: 180deg;
                transition: rotate 500ms ease-in-out;
            }
            & + .sub-ul {
                height: 0;
                @extend %hidden;
            }
        }
        .nav-anchor[aria-expanded="true"] {
            & + .sub-ul {
                height: auto;
            }
            &::after {
                rotate: 0deg;
                transition: rotate 500ms ease-in-out;
            }
        }
        &.active > .nav-anchor .nav-text {
            font-weight: 500
        }
    }
    .nav-text {
        @extend %secondary-1000;
        @extend %font-13-pr;
        @extend %py-3;
        @extend %d-block;
        @extend %w-100;
        @extend %uppercase;
    }
    .site-navigation {
        justify-content: space-between;
        height: calc(var(--window-inner-height) - (var(--header-height) + env(safe-area-inset-top) + env(safe-area-inset-bottom)));
        height: calc(100dvh - var(--header-height));
        @extend %flex-column;
        &-list {
            .current-active {
                &.submenu::after {
                    content: unset;
                }
                &::after {
                    height: 0.2rem;
                    opacity: 1;
                    @extend %primary-500-bg;
                }
                .nav-text {
                    font-weight: 500;
                }
            }
        }
    }
}
.site-navigation-list {
    @extend %overflow;
    @extend %py-1;
    @extend %px-4;
}
.btn-hamburger {
    width: 2.2rem;
    height: 3rem;
    position: relative;
    @extend %mr-3;
    @include flex-config(flex, column, space-evenly, flex-end);
    .line {
        width: 100%;
        height: 0.2rem;
        border-radius: var(--half-tag-border-radius);
        transition: 500ms ease-in-out;
        transition-property: transform, width, height, border-radius, justify-content, align-center;
        @extend %neutral-50-bg;
        &-middle {
            width: 70%;
            &::before {
                content: "";
                left: 0;
                top: 46%;
                height: 0.3rem;
                width: 0.3rem;
                border-radius: 100%;
                @extend %absolute;
                @extend %d-block;
                @extend %neutral-50-bg;
            }
        }
    }
    &[aria-expanded="true"] {
        justify-content: center;
        align-items: center;
        & + .site-nav {
            right: 0;
        }
        .line {
            position: absolute;
            height: 0.2rem;
            border-radius: var(--tag-border-radius);
            &-top {
                transform: rotate(45deg) scale(1);
            }
            &-middle {
                width: 0;
                &::before {
                    opacity: 0;
                }
            }
            &-bottom {
                transform: rotate(-45deg) scale(1);
            }
        }
        ~ .header-more-wrapper {
            right: 0;
        }
    }
}
.site-search {
    .btn-search-toggle {
        &[aria-expanded="true"] {
            .btn-text::before {
                content: "\e813";
            }
            & ~ .site-search-popup {
                transform: translateX(0);
            }
        }
    }
    &-section {
        @extend %flex-c-c;
    }
    &-popup {
        top: var(--header-height);
        left: 0;
        height: calc(var(--window-inner-height) - (var(--header-height) + env(safe-area-inset-top) + env(safe-area-inset-bottom)));
        height: calc(100dvh - var(--header-height));
        z-index: var(--z-search-popup);
        transform: translateX(100%);
        transition: 300ms transform;
        background: linear-gradient(0deg, var(--secondary-1000) 25.82%, var(--secondary-900) 113.23%);
        box-shadow: 0 0.4rem 0.6rem 0 (var(--neutral-1000), 0.1);
        @extend %fixed;
        @extend %overflow;
        @extend %w-100;
        @extend %py-6;
        @extend %px-3;
    }
    &-form {
        align-items: center;
        @extend %relative;
        @extend %flex;
        @extend %mb-4;
        @extend %mx-auto;
        @extend %w-100;
        .btn-search {
            right: var(--space-2);
            @extend %absolute;
            @extend %flex-c-c;
            @extend %neutral-50;
        }
    }
    &-input {
        border: 0;
        height: 4rem;
        -webkit-appearance: none;
        @extend %secondary-800-bg;
        @extend %w-100;
        @extend %py-2;
        @extend %pr-10;
        @extend %pl-4;
        @extend %neutral-50;
        @extend %half-radius;
        &::placeholder {
            @extend %neutral-50;
        }
    }
    .search-tabs {
        &-title {
            letter-spacing: 0.02rem;
            @extend %my-3;
            @extend %uppercase;
            @extend %font-18-pm;
        }
        &-list {
            gap: 1.5rem;
            @extend %pl-0;
            @extend %flex;
            @extend %flex-wrap;
        }
        &-item {
            @extend %secondary-1000;
            @extend %half-radius;
            @extend %py-2;
            @extend %px-3;
            @extend %primary-500-bg;
        }
    }
}
.site-login-wrap {
    .site-login[aria-expanded] {
        @extend %flex;
    }
    .site-login {
        @extend %d-none;
    }
}
.site-search-popup,
.site-nav {
    &::before {
        content: "";
        bottom: 0;
        left: 0;
        pointer-events: none;
        @extend %h-100;
        @extend %w-100;
        @extend %absolute;
    }
}
.site-nav {
    &::before {
        pointer-events: none;
        @include background(null, "patterns/mobile/sidenav.png", bottom/cover no-repeat);
    }
}
@media screen and (min-width: $tablet-min-width) {
    .sticky-header {
        .site-header-wrap {
            .site-header {
                .site-header-layout {
                    background: clr(secondary-1000);
                }
            }
        }
    }
    .home .site-header-layout {
        background-color: unset;
    }
    .site-header-layout:has(.btn-search-toggle[aria-expanded="true"]) {
        background: clr(secondary-1000);
    }
    .site-header-wrap {
        width: 100%;
        position: fixed;
        .social-list {
            position: fixed;
            flex-direction: column;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: unset;
            height: fit-content;
            border-radius: 0 0.4rem 0.4rem 0;
            background-color: hsl(var(--hsl-neutral-1000) / 0.6);
            // backdrop-filter: blur(3.45rem);
            .social-anchor {
                height: 2.3rem;
                width: 2.3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: var(--neutral-200);
                &::before {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .site {
        &-header-layout {
            padding-top: 0;
            position: relative;
            isolation: isolate;
            &::before {
                content: '';
                z-index: -1;
                width: 100%;
                height: 100%;
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(270deg, clr(neutral-1000, 3) 0%, clr(neutral-1000, 1.8) 88.29%);
            }
        }
        &-logo {
            padding: var(--space-2);
            top: 0;
            border-radius: 0 0 var(--space-1) var(--space-1);
            background: var(--secondary-900);
            z-index: var(--z-logo);
        }
        &-nav {
            width: max(25.4rem, 18.6%);
            top: 0;
            height: var(--window-inner-height);
            height: 100dvh;
            background: var(--secondary-1000);
            z-index: var(--z-index4);
            .nav-text,
            .nav-anchor {
                color: var(--neutral-50);
                font-size: 1.4rem;
                // font-weight: 500;
            }
            .nav-text {
                padding-block: var(--space-5);
            }
            .btn-close {
                display: block;
                &::before {
                    content: "close";
                    left: 1.7rem;
                    top: 2.5rem;
                    position: absolute;
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: var(--neutral-50);
                    text-transform: uppercase;
                }
            }
        }
        &-navigation {
            margin-top: var(--header-height);
            .nav-link,
            .sub-ul-link {
                &::after {
                    background: var(--neutral-50);
                }
            }
            &::after {
                content: "";
                width: 100%;
                height: 0.1rem;
                position: absolute;
                top: var(--header-height);
                left: 0;
                background: var(--neutral-50);
                opacity: 0.1;
            }
        }
        &-navigation-list {
            height: calc(var(--window-inner-height) - var(--header-height));
            height: calc(100dvh - var(--header-height));
        }
        &-redirects {
            width: unset;
            order: 2;
            margin-bottom: unset;
            background-color: unset;
            .redirect-link:last-child::after {
                display: unset;
            }
            .redirect-link {
                .redirect-text {
                    font-size: 1.2rem;
                }
                .redirect-anchor {
                    padding-inline: 1.5rem;
                }
                &:first-child {
                    .redirect-anchor {
                        padding-right: var(--space-3);
                    }
                }
                .redirect-anchor-download {
                    background: var(--primary-500);
                    padding: 0.2rem 1rem;
                    border-radius: 0.3rem;
                    color: var(--secondary-1000);
                    &::before {
                        color: var(--neutral-1000);
                    }
                }
            }
        }
        &-login-wrap {
            order: 4;
            padding-right: var(--space-3);
            .sub-ul {
                right: 10rem;
            }
            .site-login {
                margin-right: var(--space-3);
            }
        }
        &-search-section {
            order: 3;
        }
        &-search-popup {
            padding: 9.7rem;
        }
    }
    .btn-hamburger {
        order: 5;
        margin-left: var(--space-10);
        &[aria-expanded="true"] {
            z-index: var(--z-index5);
            &::before,
            &::after {
                opacity: 0;
                transition: opacity 350ms ease-in;
            }
        }
        .line {
            transition: 300ms ease;
        }
        &::after {
            left: -6.6rem;
        }
        &::before {
            content: "MENU";
            position: absolute;
            left: -5.4rem;
            color: var(--neutral-50);
            opacity: 1;
        }
    }
    .search-tabs-title {
        font-size: 2rem;
    }
    .site-search-popup {
        &::before {
            pointer-events: none;
            @include background(null, "patterns/popup-bg.png", bottom/auto repeat-x);
        }
    }
    .site-nav {
        &::before {
            bottom: 0;
            pointer-events: none;
            @include background(null, "patterns/sidenav.png", bottom/cover no-repeat);
        }
    }
    .site-token {
        left: unset;
        right: 6rem;
        width: 37.5rem;
    }
}