@use "../config" as *;
html {
    @extend %w-100;
    &.no-scroll,
    &.no-scroll body {
        height: var(--window-inner-height);
        touch-action: none;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: auto;
        @extend %hidden;
    }
}
[v-cloak] {
    visibility: hidden;
}
.grecaptcha-badge {
    visibility: hidden;
}
:where(.waf-component) {
    // @extend %p-4;
    // @extend %mb-4;
}
:where(.img-box) {
    @extend %neutral-200-bg;
    @extend %half-radius;
    @extend %hidden;
}
img {
    display: block;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
    @extend %w-100;
}
[class^="img-"] {
    @extend %relative;
    img {
        @extend %h-100;
        @extend %pos-tl;
        &[alt] {
            font-size: 1.2rem;
            @extend %neutral-900-7;
        }
    }
}
@each $width,
$height in $aspect-ratio {
    .img-#{$width}by#{$height} .img-box {
        padding-bottom: calc(($height / $width) * 100%);
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    @extend %m-0;
}
/* Firefox */
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}
.desktop {
    @extend %d-none;
}
.mobile {
    @extend %d-block;
}
// buttons style starts
.btn-site,
.btn-fill {
    @extend .btn-fill;
}
.btn-outline {
    @extend %btn-outline;
}
@each $width in $flex-width {
    .m-w-#{$width} {
        width: calc($width * 1%);
        &-gap {
            width: calc((#{$width * 1%} - var(--space-1)));
        }
    }
}
//project css start
/* web setup css done */
/* project common css start */
// max-width style
:where(.layout-wrapper) {
    max-width: var(--container-max-width);
    margin: auto;
}
// waf head common styles
:where(.waf-head) {
    .title {
        @extend %uppercase;
        @extend %secondary-1100;
        @extend %font-18-pm;
    }
    .head-wrap {
        @extend %flex-sb-c;
    }
    .head-tab {
        li {
            border: 0.1rem solid var(--accent);
            list-style: none;
            @extend %flex-c-c;
            @extend %gap-1;
            @extend %text-center;
            @extend %capitalize;
            @extend %half-radius;
            a {
                line-height: 1;
                @extend %relative;
                @extend %p-1-2;
                @extend %d-block;
                @extend %font-10-pm;
                @extend %uppercase;
                @extend %secondary-1000;
                @extend %quarter-radius;
            }
            &:first-child a {
                @extend %primary-500-bg;
            }
        }
        .active {
            border-radius: 0.3rem;
        }
    }
}
// buttons style starts
.loadmore {
    border-radius: .2rem;
    @extend %p-1-2;
    @extend %primary-500-bg;
    @extend %secondary-1000;
    @extend %uppercase;
    @extend %pointer;
}
.loadmore-wrap {
    margin: var(--space-4) auto;
    display: block;
    width: max-content;
}
.v-separator {
    position: relative;
    &:before {
        content: "";
        height: 100%;
        bottom: 0;
        right: 0;
        width: 0.1rem;
        background: clr(neutral-1000, 1);
        position: absolute;
    }
}
.scroll-top {
    z-index: var(--z-scrolltop);
    position: fixed;
    bottom: calc(var(--standings-strip-height) + 2rem);
    right: 2rem;
    height: 4rem;
    width: 4rem;
    @extend %primary-500-bg;
    @extend %circle-radius;
    @extend %d-none;
    &-btn {
        @extend %h-100;
        @extend %w-100;
    }
    .btn-top-scroll {
        width: 4rem;
        aspect-ratio: 1/1;
        @extend %relative;
        @extend %neutral-900-bg;
        @extend %half-radius;
        @extend %flex-c-c;
    }
    .scroll-top-icon::before {
        content: "\e806";
        font: 1.5rem/1.8rem "waf-font-icon";
        bottom: 0;
        @extend %absolute;
        @extend %w-100;
        @extend %h-100;
        @extend %flex-c-c;
    }
}
.table-responsive {
    @extend %mb-3;
    .table {
        overflow-x: auto;
        @extend %flex;
        .row-head {
            @extend %flex-c-c;
        }
        &-head {
            .table-data {
                @extend %secondary-900-bg;
            }
            .text {
                @extend %font-10-pb;
                @extend %primary-500;
                @extend %uppercase;
            }
        }
        &-right {
            overflow: auto;
        }
        &-row {
            height: max-content;
            @extend %flex-n-s;
        }
        &-body {
            .table-row {
                &:nth-child(even) {
                    .table-data {
                        @extend %secondary-50-bg;
                    }
                }
                &:nth-child(odd) {
                    .table-data {
                        @extend %neutral-50-bg;
                    }
                }
                &.highlight {
                    .table-data {
                        background: clr(primary-500);
                    }
                }
            }
        }
        &-left {
            width: 50%;
            .table-data {
                width: 100%;
            }
        }
        &-right {
            width: 50%;
        }
        &-data {
            min-height: 3.2rem;
            width: 8rem;
            flex-shrink: 0;
            @extend %p-3;
            @include flex-config(flex, null, center, center);
            &.text-left {
                @include flex-config(null, null, flex-start);
            }
            &.text-right {
                @include flex-config(null, null, flex-end);
            }
        }
    }
    .btn-more {
        @extend %mt-6;
    }
}
.main-wrap {
    min-height: calc(var(--window-inner-height) - 10rem);
}
.home {
    .main-wrap {
        padding-top: var(--header-height);
    }
}
.has-banner {
    .waf-breadcrumb {
        &::before {
            height: var(--shape-head, 45rem);
        }
    }
}
.no-data {
    margin-block: var(--space-5) var(--space-3);
    font-weight: 700;
    border: 3px solid clr(secondary-600);
    @extend %mx-2;
    @extend %py-3;
    @extend %d-block;
    @extend %text-center;
    @extend %secondary-1000;
    @extend %secondary-100-bg;
    @extend %half-radius;
    @extend %uppercase;
}
.no-player {
    @extend %full-radius;
    @extend %secondary-300-bg;
    @extend %h-100;
    @extend %w-100;
    @extend %flex-c-c;
    @extend %font-16-pb;
    @extend %secondary-1000;
    @extend %py-6;
}
.waf-trophy-page {
    .waf-breadcrumb {
        &::before {
            height: 20rem
        }
    }
}
.waf-trophy {
    @extend %my-8;
    .trophy-title {
        @extend %py-3;
        @extend %font-16-pb;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .no-player {
        width: 90%;
    }
    .home {
        .main-wrap {
            padding-top: 0;
        }
    }
    .main-wrap {
        margin-top: 0;
    }
    .social-module {
        display: none;
    }
    .home {
        .social-module {
            display: block;
        }
    }
    .waf-head {
        .title {
            font-size: 2.2rem;
        }
    }
    .scroll-top {
        height: 3rem;
        width: 3rem;
        bottom: calc(var(--standings-strip-height) + 3.5rem);
        .scroll-top-icon::before {
            font-size: 1.1rem;
        }
    }
}
//safari workaround for base font size
@media only screen and (max-width: $tablet-min-width) {
    :root {
        -webkit-text-size-adjust: none;
    }
}
// buttons style starts
@include mq(col-md) {
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    @each $width in $flex-width {
        .w-#{$width} {
            width: calc((#{$width * 1%} - var(--space-1)));
            &-gap {
                width: calc((#{$width * 1%} - var(--space-1)));
            }
        }
    }
    :where(.waf-head) {
        .title {
            font-size: 2.8rem;
        }
    }
}
ul {
    list-style: none;
}