@use "variables" as *;
@use "map" as *;
@each $font-family, $font-variation in $font-detail {
    @each $font-alias, $font-type in $font-variation {
        $font-family-file-name: map-get($map: $font-type, $key: "file-name");
        $font-weight: map-get($map: $font-type, $key: "weight");
        $font-style: map-get($map: $font-type, $key: "style");
        @font-face {
            font-family: $font-family;
            src: local("#{$font-family-file-name}"),
            url("/static-assets/build/fonts/#{$font-family-file-name}?v=#{$font-version}#iefix") format("embedded-opentype"),
            url("/static-assets/build/fonts/#{$font-family-file-name}.woff2?v=#{$font-version}") format("woff2"),
            url("/static-assets/build/fonts/#{$font-family-file-name}.woff?v=#{$font-version}") format("woff"),
            url("/static-assets/build/fonts/#{$font-family-file-name}.ttf?v=#{$font-version}") format("truetype"),
            url("/static-assets/build/fonts/#{$font-family-file-name}.svg#?v=#{$font-version}#{$font-family}") format("svg");
            font-weight: $font-weight;
            font-style: $font-style;
            font-display: swap;
        }
    }
}
@font-face {
    font-family: 'waf-font-icon';
    src: url('/static-assets/build/fonts/waf-font-icon.eot?v=#{$font-version}');
    src: url('/static-assets/build/fonts/waf-font-icon.eot?v=#{$font-version}#iefix') format('embedded-opentype'),
    url('/static-assets/build/fonts/waf-font-icon.woff2?v=#{$font-version}') format('woff2'),
    url('/static-assets/build/fonts/waf-font-icon.woff?v=#{$font-version}') format('woff'),
    url('/static-assets/build/fonts/waf-font-icon.ttf?v=#{$font-version}') format('truetype'),
    url('/static-assets/build/fonts/waf-font-icon.svg?v=#{$font-version}#waf-font-icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}