@use "../config" as *;
.article-item {
    width: 100%;
}
.waf-listing {
    @include background(null, "patterns/common-pattern.png", bottom/cover fixed);
}
// common style/card style
.waf-listing {
    --z-listing: 2;
    --z-reaction: 3;
    .waf-head {
        @extend %mb-5;
    }
    .meta-category {
        border-radius: .2rem;
        padding-block: .2rem;
        @extend %secondary-50-bg;
        @extend %font-10-pm;
        @extend %px-2;
        @extend %capitalize;
        @extend %mb-1;
        // @extend %py-1;
        a {
            @extend %secondary-1000;
        }
    }
    .item-type-video .item-type-icon {
        z-index: map-get($map: $zindex, $key: default);
        box-shadow: 0 0 0 .5rem hsl(var(--hsl-neutral-50) / .2), 0 0 0 1rem hsl(var(--hsl-neutral-50) / .05);
        @include position-combo(center);
        @extend %rounded-radius;
        @extend %flex;
        @extend %neutral-50-bg-3;
        @extend %font-0;
        &::before {
            font-size: 1.6rem;
            @extend %primary-500;
        }
    }
    .meta-date {
        @extend %font-12-pr;
        @extend %secondary-800;
        @extend %flex-fs-c;
        &:before {
            content: "\e850";
            font: 1rem/1.8rem "waf-font-icon";
            @extend %mr-1;
        }
    }
    .article {
        &-wrap {
            @extend %flex-column;
        }
        &-thumbnail {
            @extend %w-100;
            @extend %relative;
        }
        &-item {
            @extend %relative;
            @extend %full-radius;
            @extend %hidden;
        }
        &-title {
            @extend %mt-0;
            @include truncate(var(--_line, 2), var(--_fontsize, 18), var(--_line-height, 1.6));
        }
        &-content {
            z-index: var(--z-listing);
            flex-wrap: wrap;
            @extend %flex;
            @extend %relative;
            @extend %transparent-bg;
            @extend %w-100;
            @extend %p-3;
            .article-meta {
                a.meta-category {
                    @extend %secondary-1000;
                    @extend %font-10-pm;
                }
            }
            a,
            .icon-b-share {
                @extend %secondary-800;
            }
            > a {
                @extend %w-100;
            }
            .readmore {
                @extend %font-0;
                @extend %w-100;
                @extend %h-100;
                @extend %pos-tl;
            }
            .reaction-section {
                z-index: var(--z-reaction);
                @extend %relative;
                @extend %mt-auto;
                @extend %ml-auto;
            }
            &-meta {
                .meta {
                    &::after {
                        @extend %neutral-900-bg;
                    }
                }
            }
        }
        &-meta {
            width: calc(100% - 3.5rem);
            flex-wrap: wrap;
            @extend %p-0;
            @extend %mt-auto;
            @extend %gap-1;
            @extend %flex-n-fs;
            .meta {
                @extend %relative;
                @extend %font-12;
                @extend %m-0;
                &-author {
                    max-width: 34%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @extend %hidden;
                    @extend %font-12;
                }
                &-date {
                    order: 1;
                    @extend %font-12;
                }
            }
        }
    }
    .views-section,
    .item-type-photo .item-type-icon {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .article-list {
        @include grid(4, var(--space-4));
    }
}