@use '../config/' as *;
.tab-section {
    border: .1rem solid clr(neutral-50, 1);
    @extend %full-radius;
    @extend %my-4;
    @extend %w-100;
    @extend %hidden;
    .tabs {
        @extend %flex;
        @extend %p-0;
    }
    .tab-name {
        flex: 1;
        @extend %text-center;
        @extend %uppercase;
        .text {
            @extend %neutral-50;
            @extend %font-14;
        }
        &.active {
            @extend %primary-500-bg;
            .text {
                font-weight: 700;
                @extend %secondary-1000;
            }
        }
        &:not(:last-child) {
            @extend %relative;
            @include separator(50%, y);
        }
        .tab-text {
            @extend %p-2;
        }
    }
}