@use '../config/' as *;
.waf-listing {
    padding-block: var(--space-10);
    --_fontsize: 14;
    @include listing-card("horizontal", 10rem);
    .waf-head {
        @extend %mb-4;
    }
    .img-box {
        border-radius: 0;
    }
    .article {
        &-item {
            @extend %mb-3;
            @extend %neutral-50-bg;
        }
        &-title {
            font-weight: 500;
            @extend %mb-2;
        }
    }
    .article-content {
        --_line: 2;
        padding-top: var(--space-6);
        .meta-category {
            bottom: calc(100% - 2.5rem);
            @extend %absolute;
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        @include listing-card("vertical");
        .article-content {
            padding-top: var(--space-9);
            .meta-category {
                bottom: calc(100% - 3.5rem);
            }
        }
    }
}