@use "../config/" as *;
.item-type-icon {
    width: 3rem;
    aspect-ratio: 1/1;
    z-index: var(--zindex-video-icon);
    @extend %flex-c-c;
    &::before {
        color: var(--c-white);
    }
}
.item-type-video .item-type-icon::before {
    @include icon(play, 12);
}
.item-type-photo .item-type-icon::before {
    @include icon(play, 12);
}
.item-type-article .item-type-icon::before {
    @include icon(play, 12);
}