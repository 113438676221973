@use "../config" as *;
.waf-modal,
.modal-window {
	top: 0;
	left: 0;
	z-index: var(--z-modal);
	@extend %h-100;
	@extend %w-100;
	@extend %neutral-1000-bg-7;
	@extend %flex-c-c;
	@extend %fixed;
}
.modal {
	&-dialog {
		width: clamp(35rem, 35%, 88rem);
		@extend %neutral-50-bg;
		@extend %half-radius;
	}
	&-content {
		@extend %full-radius;
	}
	.text-secondary-1000 {
		@extend %secondary-1000;
	}
	.title {
		@extend %font-18;
		@extend %text-center;
		@extend %mb-2;
	}
	.sub-title {
		@extend %neutral-900;
		@extend %font-14-pb;
		@extend %text-center;
		@extend %mb-2;
	}
	.description {
		@extend %font-14-pr;
		@extend %mb-2;
		@extend %text-center;
	}
	.btn {
		flex-shrink: 1;
		@extend %w-100;
		@extend %uppercase;
		@extend %font-12-pb;
	}
	.btn-fill-secondary {
		@extend .btn-fill-secondary;
		.btn-text {
			@extend %primary-500;
		}
	}
	.modal-footer {
		gap: var(--space-2);
		@extend %flex-c-n;
	}
	.content-title {
		@extend %font-14-pr;
		@extend %text-center;
		@extend %mb-2;
		@extend %neutral-900;
	}
	.icon-box {
		width: 5rem;
		@extend %mb-3;
		@extend %mx-auto;
		img {
			width: 100%;
			height: 100%;
		}
	}
}
.waf-head ~ .waf-footer .waf-modal {
	display: none;
}
.waf-head.active ~ .waf-footer .waf-modal {
	display: flex;
}
.waf-player {
	.modal {
		&-dialog {
			background: linear-gradient(0deg, clr(secondary-1000) 25.82%, clr(secondary-900) 113.23%);
		}
		.title {
			@extend %font-20-pm;
			@extend %uppercase;
			@extend %mb-2;
			@extend %neutral-50;
		}
		.description {
			line-height: 1.8rem;
			max-height: calc(var(--window-inner-height) - 25rem);
			@extend %overflow;
			@extend %font-12-pr;
			@extend %neutral-50-8;
		}
		&-dialog {
			width: clamp(35rem, 75%, 88rem);
		}
		&-content {
			@extend %relative;
		}
		.btn-close {
			height: 3rem;
			width: 3rem;
			top: -1rem;
			right: -1rem;
			@extend %absolute;
			@extend %circle-radius;
			@extend %primary-500-bg;
			@extend %font-0;
			&::before {
				content: "\e813";
				font: 1rem/1.8rem "waf-font-icon";
			}
		}
		.btn-submit,
		.sub-title {
			display: none;
		}
	}
}
// ---sync-calendar-modal--
.modal-window {
	--modal-width: 32rem;
	flex-direction: column;
	.modal-head {
		width: var(--modal-width);
		@extend %relative;
	}
	.modal-body {
		width: var(--modal-width);
		@extend %text-center;
		@extend %neutral-50-bg;
		@extend %p-4-5;
		@extend %half-radius;
	}
	.btn-close {
		right: var(--space-2);
		top: var(--space-2);
		@extend %absolute;
		.btn-text {
			&::before {
				content: "\e813";
				font: 1rem/1.8rem "waf-font-icon";
			}
		}
	}
	.fav-club-head {
		.title {
			@extend %font-18-pb;
			@extend %secondary-1000;
			@extend %uppercase;
			@extend %mb-3;
		}
		.text {
			@extend %font-14-pr;
			@extend %mb-4;
		}
	}
	.social-list {
		border: none;
		@extend %flex-c-c;
		@extend %gap-10;
		.name {
			@extend %font-14-pm;
			@extend %capitalize;
		}
	}
	.email-form {
		@extend %mt-5;
		.email-element {
			align-items: self-start;
			@extend %flex-column;
		}
		.email-label {
			@extend %font-12-pr;
			@extend %neutral-800;
			@extend %mb-2;
			@extend %capitalize;
		}
		.email-input {
			border: .1rem solid clr(neutral-100);
			@extend %half-radius;
			@extend %w-100;
			@extend %p-2-3;
			@extend %mb-3;
		}
		.email-btn {
			@extend %secondary-1000;
			@extend %primary-500-bg;
			@extend %font-14-pb;
			@extend %text-center;
			@extend %w-100;
			@extend %p-2;
			@extend %uppercase;
			@extend %half-radius;
		}
	}
}
.waf-popup {
	display: none;
	&.active {
		display: flex;
	}
	.modal {
		&-head {
			.btn-text {
				@extend %font-0;
			}
			.btn-close {
				right: var(--space-3);
				top: var(--space-3);
				width: unset;
				@extend %absolute;
				&::after {
					@include icon(close, 16);
					@extend %neutral-50;
				}
			}
		}
		&-dialog {
			overflow: hidden;
			@extend %secondary-900-bg;
			// height: 40rem;
			// @include background(null, "banner/mobile/app-ad-bg.png", top center / cover no-repeat);
			@extend .full-width;
		}
		&-content {
			@extend %relative;
			@extend %neutral-50;
			@extend %text-center;
			@extend %font-12;
		}
		&-footer {
			width: 42%;
			position: absolute;
			left: 45%;
			bottom: 4%;
		}
	}
	.image-wrap {
		border-bottom: 0.2rem solid var(--primary-500);
		max-width: 25rem;
		height: 17rem;
		@extend %mx-auto;
		img {
			height: 100%;
			width: 100%;
		}
	}
	.title {
		@extend %mt-3;
		@extend %primary-500;
		@extend %font-21-pb;
	}
	.btn-wrap {
		gap: var(--space-1);
		@extend %mt-3;
		@extend %flex-c-n;
	}
	.store {
		// width: 13.6rem;
	}
}
@media screen and (min-width: $tablet-min-width) {
	.waf-popup {
		.modal {
			&-dialog {
				width: 40rem;
			}
		}
	}
	.modal {
		.description {
			margin-bottom: var(--space-4);
		}
		.btn {
			width: 60%;
		}
	}
	.waf-player {
		.modal {
			&-content {
				padding: var(--space-8);
			}
		}
	}
	.modal-window {
		--modal-width: 42rem;
		.modal-body {
			padding: 3.5rem 5rem;
		}
		.fav-club-head {
			.title {
				font-size: 2.2rem;
			}
			.text {
				font-size: 1.6rem;
			}
		}
	}
	.waf-calendar-sync {
		--modal-width: 42rem;
		padding-top: var(--header-height);
		padding: var(--space-7) var(--space-10);
		.fav-club-head {
			.title {
				font-size: 2.2rem;
			}
			.text {
				font-size: 1.6rem;
			}
		}
	}
}