@use "../config/" as *;
body {
    &.webview {
        .site-header-wrap,
        > .footer-wrap {
            @extend %d-none;
        }
        .waf-breadcrumb {
            @extend %p-0;
            .layout-wrapper {
                @extend %d-none;
            }
        }
        .waf-ad {
            @extend %mb-0;
        }
        .waf-head {
            @extend %pt-6;
        }
        .head-wrap,
        .waf-head {
            .title {
                @extend %d-none;
            }
        }
        .filter-section {
            @extend %px-0;
            @extend %transparent-bg;
            .btn-more {
                top: var(--space-2);
                @extend %ml-auto;
            }
        }
        .player-profile {
            @extend %mt-2;
            .waf-head {
                @extend %pt-0;
            }
        }
        .waf-statsdetail {
            .btn-more {
                margin-inline: auto 0;
            }
        }
        .waf-fixtures {
            .waf-head {
                @extend %pt-3;
            }
            .filter-actions {
                .btn-more {
                    @extend %absolute;
                }
            }
            .download-pdf a {
                background-color: transparent;
            }
        }
        .waf-squad {
            .waf-head {
                @extend %pt-0;
            }
            .filter-section {
                .btn-more {
                    position: unset;
                    @extend %ml-auto;
                }
            }
        }
        .waf-fixtures-page {
            .head-tab {
                @extend %mt-0;
                @extend %pl-0;
            }
        }
        .head-disclosure {
            font-size: 1rem;
        }
        .kc-sponsors {
            header,
            .footer-middle-section,
            .footer-bottom-section {
                display: none;
            }
        }
        .waf-cricketscorecard {
            .card {
                &-section {
                    padding-top: 0;
                }
            }
            .waf-body {
                padding-bottom: 6.4rem;
            }
        }
        .waf-player {
            margin-top: var(--space-1);
            .waf-head {
                padding-top: 0;
            }
        }
        &.sticky-score-card {
            .waf-cricketscorecard {
                --_z-score-card: 6;
                --_z-sticky-tab: 6;
                .card-body {
                    top: 0;
                }
                .secondary-tabs {
                    top: var(--sticky-score-board-height);
                    z-index: var(--_z-sticky-tab);
                }
            }
        }
    }
}