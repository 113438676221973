@use "../config" as *;
.footer {
  &-wrap {
    z-index: var(--z-footer);
    @extend %relative;
    @extend %neutral-50-bg;
    // @extend %pt-3;
  }
  &-top {
    @extend %pb-5;
    &:empty {
      @extend %d-none;
    }
    .client-head {
      @extend %my-5;
    }
    .client-title {
      @extend %text-center;
      @extend %secondary-1000;
      @extend %font-16-pm;
      @extend %uppercase;
    }
    .client-section-list {
      @extend %relative;
      @extend %pb-5;
      &:not(:last-child):before {
        content: "";
        height: 0.1rem;
        bottom: 0;
        @extend %neutral-1000-bg-1;
        @extend %w-100;
        @extend %absolute;
      }
      &:first-child {
        .client-logo {
          width: 17rem;
          .client-img {
            @extend %h-100;
          }
          &.client-bkt {
            a {
              height: 70%;
            }
          }
        }
        .client-list-wrap {
          gap: var(--space-3);
        }
      }
    }
    .client-list-wrap {
      gap: var(--space-2);
      flex-wrap: wrap;
      @extend %flex-c-c;
    }
    .client-logo {
      width: 12rem;
      height: 7rem;
      border: 0.03rem solid clr(neutral-1000, 1);
      @extend %p-1;
      @extend %flex-c-c;
      a {
        width: 100%;
        height: 100%;
      }
      .client-img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &-middle {
    height: var(--footer-middle-height);
    @extend %secondary-900-bg;
    .footer-link {
      @extend %relative;
      @extend %px-2;
      &-list {
        flex-wrap: wrap;
        @extend %h-100;
        @extend %p-3;
        @extend %flex-c-c;
      }
      &-anchor {
        @extend %neutral-50;
        @extend %font-12-pr;
      }
      &:not(:first-child):after {
        content: "";
        width: 0.1rem;
        height: 1.5rem;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @extend %neutral-50-bg;
      }
    }
  }
  &-bottom {
    @extend %secondary-1000-bg;
    .copyright {
      height: var(--footer-bottom-height);
      @extend %neutral-50;
      @extend %font-12-pr;
      @extend %p-3;
      @extend %flex-sb-c;
    }
    .footer-logo {
      width: 8.2rem;
      height: 3.2rem;
    }
    .powered-logo::before {
      content: "";
      @include background(null,
        "cssimages/powered-logo.png",
        center / contain no-repeat);
      @extend %w-100;
      @extend %h-100;
      @extend %d-inline-block;
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .footer {
    &-wrap {
      padding-inline: 0;
    }
    &-top {
      .layout-wrapper {
        padding-top: var(--space-3);
      }
      .client-title {
        font-size: 2rem;
      }
      .client-section-list:not(:first-child) {
        display: flex;
        justify-content: center;
      }
      .client-section-list {
        &:first-child {
          .client-logo {
            width: 22rem;
            &.client-bkt {
              a {
                height: 60%;
              }
            }
          }
        }
      }
      .client-logo {
        width: 18rem;
        height: 10rem;
      }
    }
    &-middle {
      .footer-link {
        &-anchor {
          font-size: 1.3rem;
        }
      }
    }
    &-bottom {
      .copyright {
        font-size: 1.3rem;
      }
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .footer {
    &-top {
      .client-section-list {
        &:first-child {
          .client-logo {
            height: 10rem;
          }
        }
      }
      .client-logo {
        width: 16rem;
        height: 7rem;
      }
    }
  }
}