@use "../config/" as *;
@each $icon, $value in $social {
	.icon-b-#{$icon} {
		&::before {
			content: $value;
		}
	}
}
.social-share {
	line-height: 1;
	@extend %relative;
	.share-label {
		@extend %d-none;
	}
	.icon-b-share::before {
		font-size: 1.4rem;
		@extend %neutral-50-8;
	}
	&.active .social-share-wrap {
		width: fit-content;
		border-bottom-right-radius: var(--full-radius);
		@include position-combo("y-center", 0);
		@extend %p-3-4;
		@extend %d-block;
		@extend %secondary-50-bg;
	}
	i {
		@extend %font-0;
		&::before {
			font: 400 1.3rem/1 $font-icon;
			@extend %neutral-50;
		}
	}
	.social {
		&-share-wrap {
			@extend %d-none;
			button {
				width: 2.7rem;
				aspect-ratio: 1;
				@extend %circle-radius;
				@extend %flex-c-c;
				@extend %secondary-1000-bg;
				&::before {
					content: unset;
				}
			}
			.close {
				@extend %neutral-900-bg;
			}
		}
		&-wrap {
			gap: 2.2rem;
			@extend %flex;
		}
		&-items {
			gap: 1.1rem;
			@extend %flex;
		}
	}
	.icon-copylink {
		@extend %relative;
		&[aria-expanded="true"] {
			&:before {
				content: "Copied to Clipboard";
				width: max-content;
				right: 0;
				transition: ease 1s;
				animation: fadeIn 3s forwards;
				@extend %neutral-50-bg;
				@extend %half-radius;
				@extend %secondary-1000;
				@extend %p-1;
				@extend %flex;
				@extend %pos-y-center;
				
			}
		}
	}
}
