@use "../config" as *;
.waf-select-box {
    --z-dropdown: 5;
    @extend %relative;
    @extend %neutral-50-bg;
    @extend %p-2;
    @extend %half-radius;
    .dropdown-close {
        @extend %d-none;
    }
    &.active {
        .select-box-wrap {
            @extend %d-block;
        }
        .selected-title {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    .selected-title {
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @extend %relative;
        @extend %font-16-pb;
        @extend %w-100;
        @extend %h-100;
        @extend %flex-column-c-fs;
        .title {
            @extend %m-0;
            @extend %neutral-900;
            @extend %font-14-pb;
        }
        .sub-title {
            @extend %font-12-pr;
        }
        &::after {
            transition: 0.3s;
            @include position(null, var(--space-2));
            @include icon(chevron-down, 12);
            @extend %secondary-1000;
        }
    }
    .select-box-wrap {
        min-width: 27rem;
        box-shadow: -0.2rem 0.2rem 1rem -0.5rem hsl(var(--hsl-neutral-900) / 0.7);
        z-index: var(--z-dropdown);
        top: 100%;
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        transition: 0.3s;
        @extend %d-none;
        @extend %my-0;
        @extend %mx-auto;
        @extend %text-center;
        @extend %p-0;
        @extend %neutral-500-bg;
        @extend %pos-tl;
    }
    .select-list {
        overflow: auto;
        max-height: 30rem;
        overflow-x: hidden;
        @extend %pl-0;
        .list-item {
            @extend %font-14-pr;
            @extend %w-100;
            @extend %relative;
            @extend %half-radius;
            @extend %d-block;
            @extend %p-2;
            @extend %text-left;
            &:not(:last-child) {
                &::before {
                    content: "";
                    height: 0.1rem;
                    @extend %w-100;
                    @extend %neutral-900-bg-1;
                    @extend %pos-bl;
                }
            }
            &.active {
                @extend %primary-500-bg;
                @extend %secondary-1000;
            }
            a {
                color: inherit;
                font-size: inherit;
                @extend %d-block;
            }
        }
    }
}