@use "variables" as *;

[class^="icon-"] {
	font-size: 0;
	font-style: normal;
	color: var(--white);
}

[class^="icon-"]::before {
	font-family: $font-icon;
	font-size: 1.8rem;
	font-style: normal;
	font-weight: normal;
	speak: never;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-arrow-left:before { content: '\e800'; } /* '' */
.icon-arrow-right:before { content: '\e801'; } /* '' */
.icon-arrow-up:before { content: '\e802'; } /* '' */
.icon-arrow-down:before { content: '\e803'; } /* '' */
.icon-chevron-left:before { content: '\e804'; } /* '' */
.icon-chevron-right:before { content: '\e805'; } /* '' */
.icon-chevron-up:before { content: '\e806'; } /* '' */
.icon-chevron-down:before { content: '\e807'; } /* '' */
.icon-facebook:before { content: '\e808'; } /* '' */
.icon-filter:before { content: '\e809'; } /* '' */
.icon-link:before { content: '\e80a'; } /* '' */
.icon-play-store:before { content: '\e80b'; } /* '' */
.icon-app-store:before { content: '\e80c'; } /* '' */
.icon-tiktok:before { content: '\e80d'; } /* '' */
.icon-whatsapp-o:before { content: '\e80e'; } /* '' */
.icon-twitter:before { content: '\e80f'; } /* '' */
.icon-instagram:before { content: '\e810'; } /* '' */
.icon-linkedin:before { content: '\e811'; } /* '' */
.icon-pen:before { content: '\e812'; } /* '' */
.icon-close:before { content: '\e813'; } /* '' */
.icon-call:before { content: '\e814'; } /* '' */
.icon-play:before { content: '\e815'; } /* '' */
.icon-android:before { content: '\e816'; } /* '' */
.icon-info:before { content: '\e817'; } /* '' */
.icon-user:before { content: '\e818'; } /* '' */
.icon-checkmark:before { content: '\e819'; } /* '' */
.icon-search:before { content: '\e81a'; } /* '' */
.icon-eye:before { content: '\e81c'; } /* '' */
.icon-eye-close:before { content: '\e81d'; } /* '' */
.icon-share-o:before { content: '\e81f'; } /* '' */
.icon-share:before { content: '\e820'; } /* '' */
.icon-facebook-circle:before { content: '\e821'; } /* '' */
.icon-whatsapp:before { content: '\e822'; } /* '' */
.icon-reset:before { content: '\e83c'; } /* '' */
.icon-download:before { content: '\e863'; } /* '' */
.icon-bag:before { content: '\e864'; } /* '' */
.icon-ticket:before { content: '\e865'; } /* '' */
.icon-checkmark-circle:before { content: '\f06d'; } /* '' */
.icon-youtube:before { content: '\f16a'; } /* '' */
.icon-reward:before { content: '\e866'; } /* '' */
.icon-balance-token:before { content: '\e867'; } /* '' */
.icon-token:before { content: '\e868'; } /* '' */