// shimmer animation starts
@keyframes shimmerAnimation {
  100% {
    transform: translateX(100%);
  }
}
// shimmer animation starts
// blinker animation
@keyframes blinker {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
// blinker animation
@-moz-keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}
@-o-keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}
@-webkit-keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}
@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 0 100;
  }
}
//
@keyframes squadBulletAnimation {
  100% {
    opacity: 1;
    transform: translateY(9rem);
  }
}
@keyframes marquee {
  0% {
    transform: translateX(calc(100vw - 30rem));
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
    opacity: 0;
  }
}