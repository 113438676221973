@use "../config" as *;
.swiper {
	&-button-next,
	&-button-prev {
		width: var(--swiper-button-width);
		aspect-ratio: 1/1;
		@extend %half-radius;
		@extend %flex-c-c;
		&.swiper-button-disabled {
			@extend %secondary-800-bg;
			&::after {
				@extend %neutral-50;
			}
		}
		&::after {
			font: 1.4rem/1 $font-icon;
			@extend %neutral-1000;
		}
	}
	&-button-prev {
		@extend %primary-500-bg;
		&::after {
			@include icon(long-arrow-left);
		}
	}
	&-button-next {
		@extend %primary-500-bg;
		&::after {
			@include icon(long-arrow-right);
		}
	}
	&-pagination-bullet {
		width: 1rem;
		height: .2rem;
		border-radius: var(--half-radius);
		background-color: hsl(var(--hsl-neutral-50)/7);
		&-active-main {
			width: 2rem;
			height: .4rem;
			background-color: var(--primary-500);
		}
	}
}