@use "../config" as *;
.filter-section {
    --z-filter: 12;
    --z-backdrop: 11;
    @extend %neutral-50-bg;
    @extend %pr-4;
    @extend %flex-sb-c;
    @extend %half-radius;
    &.active {
        .more-filters {
            bottom: 0;
        }
        &:before {
            content: "";
            height: var(--window-inner-height);
            width: var(--window-inner-width);
            top: 0;
            left: 0;
            z-index: var(--z-backdrop);
            @extend %neutral-1000-bg-7;
            @extend %fixed;
        }
    }
    .select-box-wrap {
        @extend %py-2;
    }
    .btn-more {
        top: -0.5rem;
        right: 0;
        box-shadow: 0 0.4rem 1rem 0 clr(neutral-1000, 4);
        border: .12rem solid clr(secondary-900, 7);
        width: max-content;
        @extend %secondary-1000-bg;
        @extend %absolute;
        @extend %p-1-2;
        @extend %half-radius;
        @extend %flex-c-c;
        @extend %neutral-50;
        &::before {
            @include icon(filter, 11);
        }
        .btn-text {
            @extend %uppercase;
            @extend %font-12;
            @extend %ml-1;
        }
        & + .btn-resetfilter {
            @extend %d-none;
        }
    }
    .filter {
        &-wrap {
            flex-basis: calc((100% - 6.5rem));
            @extend %h-100;
            @extend %d-none;
            .selected-title {
                @extend %pr-2;
                &::after {
                    @extend %d-none;
                }
            }
            .waf-select-box {
                width: 20rem;
                @extend %pl-4;
                @extend %h-100;
                &:not(:first-child) {
                    @extend %d-none;
                }
            }
        }
        &-head {
            .title {
                @extend %font-14-pb;
                @extend %neutral-1000;
                @extend %m-0;
                @extend %pb-0;
            }
        }
    }
    .dropdown-close {
        @extend %d-none;
    }
    .btn-resetfilter {
        &::before {
            @include icon(reset, 14);
        }
        .btn-text {
            @extend %font-0;
        }
    }
    .more-filters {
        z-index: var(--z-filter);
        bottom: 0;
        right: 0;
        bottom: -100%;
        transition: bottom 500ms ease-in-out;
        @extend %h-100;
        @extend %pb-6;
        @extend %fixed;
        @extend %w-100;
        @extend %neutral-50-bg;
        .waf-select-box {
            border-radius: 0;
            @extend %neutral-50-bg;
            @extend %p-2-3;
            &:not(:last-child) {
                @include border(1, black, 3, bottom);
            }
        }
        .selected-title {
            .sub-title {
                @extend %neutral-900;
                @extend %font-12-pr;
                @extend %mb-2;
            }
            .title {
                @extend %font-14-pb;
                @extend %pb-0;
            }
        }
        .select {
            &-box-wrap {
                position: unset;
                box-shadow: unset;
                @extend %neutral-50-bg;
            }
            &-list {
                padding-left: unset;
                overflow: unset;
                max-height: unset;
                .list-item {
                    z-index: var(--z-index1);
                    @extend %font-14-pr;
                    @extend %neutral-900;
                    @extend %px-2;
                    @extend %flex-fs-c;
                    &:not(:last-child) {
                        &::before {
                            // height: 0;
                            width: calc(var(--window-inner-width) - var(--space-6));
                        }
                    }
                    &.active {
                        background: none;
                        width: max-content;
                        @extend %secondary-1000;
                        &:after {
                            content: "";
                            height: 2rem;
                            left: 0;
                            z-index: -1;
                            @extend %w-100;
                            @extend %half-radius;
                            @extend %primary-500-bg;
                            @extend %absolute;
                        }
                    }
                }
            }
        }
        .head {
            background: linear-gradient(90deg, clr(secondary-1000) -0.28%, clr(secondary-900) 121.18%);
            height: 5.4rem;
            @extend %flex-sb-c;
            @extend %p-3;
            @extend %mb-2;
            // @extend %secondary-900-bg;
            .title {
                @extend %neutral-50;
                @extend %font-18-pm;
                @extend %pb-0;
                @extend %capitalize;
            }
        }
        .btn-cancel {
            @extend %neutral-50;
            .btn-text {
                @extend %d-none;
            }
            &::after {
                @include icon(close, 15);
            }
        }
        .btn-resetfilter {
            .btn-text {
                font-size: 1.2rem;
            }
        }
        .body {
            height: calc(100% - 11.5rem);
            @extend %overflow;
        }
        .footer {
            height: 7rem;
            @extend %px-3;
            @extend %flex-c-c;
            .btn {
                min-width: 14rem;
                width: 90%;
                @extend %p-2;
                @extend %uppercase;
            }
            .btn-site {
                @extend %my-2;
            }
            .btn-applyfilter {
                border: 0.1rem solid clr(primary-500);
                @extend %font-12-pb;
                @extend %flex-c-c;
                @extend %primary-500-bg;
            }
            .btn-resetfilter {
                border: 0.1rem solid clr(neutral-200);
                @extend %ml-4;
                @extend %half-radius;
                &::before {
                    order: 2;
                    @extend %pl-2;
                    @extend %mr-1;
                    @include icon(reset, 15);
                }
            }
            .btn-applyfilter {
                @extend %half-radius;
                .btn-text {
                    @extend %secondary-1000;
                }
            }
        }
    }
}
@include mq(col-md) {
    .filter-section {
        .filter-wrap {
            .waf-select-box {
                &:not(:first-child) {
                    display: block;
                }
                &:after {
                    content: "";
                    background: hsl(var(--hsl-black) / 0.3);
                    height: 80%;
                    width: 0.1rem;
                    transform: translateY(-50%);
                    @include position(50%, 0);
                }
            }
            .selected-title {
                padding-right: 0;
                &::after {
                    display: block;
                }
            }
        }
        .btn-resetfilter {
            width: 10rem;
            gap: var(--space-2);
            .btn-text {
                font-size: 1.2rem;
                order: -1;
            }
        }
        .more-filters {
            box-shadow: 0.1rem 0.1rem 1rem hsl(var(--hsl-black) / 0.3);
            height: 100%;
            width: max(35.4rem, 18.6%);
            .select-box-wrap {
                width: 100%;
                .list-item:not(:last-child) {
                    &::before {
                        width: max(31.4rem, 18.6%);
                    }
                }
            }
        }
        .btn-more {
            top: .3rem;
        }
    }
}