@use "../config" as *;
.waf-ad {
  @extend %p-2;
  @extend %mx-auto;
  &-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: var(--neutral-300);
    @extend %my-2;
    @extend %mx-auto;
    .waf-ad-gpt {
      width: max-content;
      height: max-content;
      @extend %m-auto;
    }
  }
  &.waf-ad-large-leaderboard,&.waf-ad-content-leaderboard {
    .waf-ad-wrapper {
      width: 32rem;
      height: 10rem;
    }
  }
  &.waf-ad-leaderboard {
    .waf-ad-wrapper {
      width: 32rem;
      height: 5rem;
    }
  }
  &.waf-ad-rectangle {
    .waf-ad-wrapper {
      width: 32rem;
      height: 10rem;
    }
  }
  &.waf-ad-mrec {
    .waf-ad-wrapper {
      width: 30rem;
      height: 25rem;
    }
  }
  &.waf-ad-halfpage {
    .waf-ad-wrapper {
      width: 30rem;
      height: 60rem;
    }
  }
  &.waf-ad-skyscraper {
    .waf-ad-wrapper {
      width: 12rem;
      height: 60rem;
    }
  }
}
.sticky-data-container .site-skyscraper {
  display: none;
}
@media screen and (min-width: $tablet-min-width) {
  .waf-ad {
    &.waf-ad-large-leaderboard {
      .waf-ad-wrapper {
        width: 72.8rem;
        height: 9rem;
      }
    }
    &.waf-ad-leaderboard,&.waf-ad-content-leaderboard {
      .waf-ad-wrapper {
        width: 46.8rem;
        height: 6rem;
      }
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-ad {
    &.waf-ad-leaderboard,&.waf-ad-content-leaderboard {
      .waf-ad-wrapper {
        width: 72.8rem;
        height: 9rem;
      }
    }
  }
}
@media screen and (min-width: $large-desktop-min-width) {
  .waf-ad {
    &.waf-ad-large-leaderboard {
      .waf-ad-wrapper {
        width: 97rem;
        height: 9rem;
      }
    }
    &.waf-ad-leaderboard,&.waf-ad-content-leaderboard {
      .waf-ad-wrapper {
        min-width: 72.8rem;
        max-width: 97rem;
        width: unset;
        height: 9rem;
      }
    }
  }
  .sticky-data-container {
    > div {
      @include flex-config(flex, null, space-evenly, null);
    }
    .site-skyscraper {
      display: block;
      width: 14rem;
    }
    .site-data-content {
      width: 100%;
      padding-inline: var(--space-4);
    }
  }
}
@media screen and (min-width: $xl-desktop-min-width) {
  .waf-ad {
    &.waf-ad-large-leaderboard {
      .waf-ad-wrapper {
        width: 97rem;
        min-height: 9rem;
        max-height: 25rem;
        height: unset;
      }
    }
    &.waf-ad-leaderboard,&.waf-ad-content-leaderboard {
      .waf-ad-wrapper {
        width: 97rem;
        height: 9rem;
      }
    }
    &.waf-ad-skyscraper {
      .waf-ad-wrapper {
        width: 16rem;
      }
    }
  }
  .sticky-data-container {
    .site-skyscraper {
      width: 18rem;
    }
  }
}