// this is kept just for color refence
$colors: (
	primary:( //
		50: (48, 83%, 98%), //#FEFCF4
		100:(43, 100%, 96%), //#FFF9EA
		200:(45, 100%, 86%), //#FFEEBA
		300:(45, 100%, 77%), //#FFE28A
		400:(45, 100%, 68%), //#FFD65B
		500:(45, 89%, 55%), //#F2C029
		600:(45, 80%, 45%), //#D0A217
		700:(45, 89%, 36%), //#AE850A
		800:(45, 100%, 27%), //#8C6900
		900:(45, 100%, 21%), //#6A5000
		1000:(45, 100%, 14%), //#483600
	),
	secondary:( //
		50:(260, 16%, 96%), //#F5F4F7
		100:(267, 100%, 98%), //#FAF6FF
		200:(264, 100%, 95%), //#EFE4FF
		300:(265, 100%, 91%), //#E4D1FF
		400:(264, 100%, 87%), //#D9BFFF
		500:(265, 100%, 84%), //#CEACFF
		600:(264, 63%, 72%), //#B08CE5
		700:(264, 42%, 59%), //#8F6CC3
		800:(264, 34%, 47%), //#704FA1
		900:(265, 40%, 35%), //#54367F
		1000:(264, 46%, 25%), //#3A225D
		1100:(266, 48%, 25%), // #3B215D;
	),
	neutral: ( //
		50: (0, 0%, 100%), //#FFFFFF
		100: (0, 0%, 71%), //#B6B6B6
		200: (0, 0%, 69%), //#B1B1B1
		300: (0, 0%, 67%), //#ABABAB
		400: (0, 0%, 62%), //#9E9E9E
		500: (0, 0%, 49%), //#7C7C7C
		600: (0, 0%, 38%), //#616161
		700: (0, 0%, 23%), //#3C3C3C
		800: (0, 0%, 16%), //#2A2A2A
		900: (0, 0%, 5%), //#0E0E0E
		1000: (0, 0%, 0%), //#000000
	),
	success: ( //
		500: (145, 75%, 41%), //#1AB85B
	),
	warning: ( //
		500: (40, 100%, 48%), //#F5A500
	),
	error: ( //
		500: (360, 81%, 58%), //#EA3C3D
	),
	team:( //
		dc: (219, 59%, 36%), //#264C93
		rr: (322, 95%, 46%), //#E50695
		mi: (208, 100%, 31%), //#00569F
		rc: (356, 99%, 45%), //#E70112
		lsg: (217, 99%, 44%), //#0156E1
		gt: (218, 64%, 14%), //#0D1E3C
		pk: (359, 78%, 50%), //#E31D1F
		sr: (359, 78%, 50%), //#E31D1F
		csk: (213, 77%, 31%), //#12498B
	),
	spider:( //
		1s: (34, 100%, 47%), // #F18700
		2s: (73, 47%, 51%), // #A3BD47
		3s: (309, 100%, 55%), // #FF18DD
		4s: (149, 100%, 32%), // #00A24D
		5s: (206, 100%, 46%), // #0087ED 
	),
	auction:(sold:(0, 87%, 39%), //#bb0d0d
		unsold:(211, 77%, 30%), //#124b89
		rtm:(211, 78%, 36%), //#1459a2
		auction:(18, 84%, 54%), //#ec6529
	)
);
$aspect-ratio: (
	4 3,
	3 4,
	16 9,
	1 1,
	2 3,
	9 16,
	21 9,
	18 9,
	18 6,
	6 4,
	4 6
);
$flex-width: (
	10,
	20,
	25,
	30,
	33,
	40,
	50,
	60,
	70,
	80,
	100
);
$font-detail: (
	Rubik: ( //
		pr: (file-name: Rubik-Regular,
			weight: 400,
			style: normal),
		pm: (file-name: Rubik-Medium,
			weight: 500,
			style: normal),
		pb: (file-name: Rubik-Bold,
			weight: 700,
			style: normal))
);
$directions: (
	t: -top,
	r: -right,
	b: -bottom,
	l: -left,
	x: -inline,
	y: -block
);
$flex-spacing: (
	-sa: space-around,
	-sb: space-between,
	-se: space-evenly,
	-fe: flex-end,
	-fs: flex-start,
	-c: center,
	-s: stretch,
	-u: unset,
	-n: null
);
$media-query: (
	col: 300px,
	col-sm: 576px,
	col-md: 768px,
	col-lg: 992px,
	col-xl: 1200px,
	col-xxl: 1400px,
	col-xxxl: 1600px,
);
$zindex: (
	patterns:-1,
	header:11,
	overlay:1,
	video-icon:2,
	showcase:3,
	icon:4,
	timer:5,
	modal:20,
	modal-close:21,
	modal-swiper:22,
	modal-share:22,
	negative:-1,
	marquee:3,
	default: 1,
	scrolltop:10,
	profile-pattern: -2,
	footer:2,
	blog-title:3,
	blog-icon:4,
	score-card: 2,
	country: 2,
	search-popup:6,
	logo:7,
	standing:12,
	select-box: 3,
	sticky-tab: 2,
	showcase-content: 2
);
$icons: (
	arrow-left: '\e800',
	arrow-right: '\e801',
	arrow-up: '\e802',
	arrow-down: '\e803',
	chevron-left: '\e804',
	chevron-right: '\e805',
	chevron-up: '\e806',
	chevron-down: '\e807',
	facebook: '\e808',
	filter: '\e809',
	link: '\e80a',
	play-store: '\e80b',
	app-store: '\e80c',
	tiktok: '\e80d',
	whatsapp-o: '\e80e',
	twitter: '\e80f',
	instagram: '\e810',
	linkedin: '\e811',
	pen: '\e812',
	close: '\e813',
	call: '\e814',
	play: '\e815',
	android: '\e816',
	info: '\e817',
	user: '\e818',
	checkmark: '\e819',
	search: '\e81a',
	message: '\e81b',
	eye: '\e81c',
	eye-close: '\e81d',
	share-o: '\e81f',
	share: '\e820',
	facebook-circle: '\e821',
	whatsapp: '\e822',
	arrow-down-1: '\e830',
	trending: '\e831',
	wicket-keeper: '\e832',
	bowler: '\e833',
	all-rounder: '\e834',
	batter: '\e835',
	reset: '\e83c',
	cap: '\e849',
	calendar: '\e850',
	stopwatch: '\e851',
	double-arrow: '\e852',
	copy-link: '\e860',
	download: '\e863',
	shop: '\e864',
	ticket: '\e865',
	reward: '\e866',
	balance-token: '\e867',
	token: '\e868',
	venue: '\e87e',
	flight: '\e887',
	long-arrow-left: '\e888',
	long-arrow-right: '\e889',
	checkmark-circle: '\f06d',
	youtube: '\f16a',
	commentary: '\e878',
	live-blog: '\e880',
	scorecard: '\e87b',
	graphs: '\e879',
	match-info: '\e817',
	setting: '\e884',
	edit: '\e885',
	delete: '\e886',
	camera: '\e823',
	logout: '\e824',
	replace: '\e81e',
	in-out: '\e87d',
	wicket: '\e881',
	auction: '\e856',
	unsold: '\e857',
	sold: '\e858',
	rtm: '\e859',
	teams: '\e96c',
);
$social: (
	facebook: "\e808",
	youtube: "\f16a",
	tiktok: "\e80d",
	whatsapp: "\e80e",
	twitter: "\e80f",
	x: "\e80f",
	instagram: "\e810",
	linkedin: "\e811",
	copylink: "\e80a",
	app-store: "\e80c",
	google-play-store: "\e80b",
	reward: "\e866",
	balance-token: "\e867",
	token: "\e868",
	share: "\e81f",
	close: "\e813",
	email: "\e81b",
);
$redirect: (
	download: "\e863",
	shop: "\e864",
	ticket: "\e865",
);
$squad: (
	wicket-keeper: '\e832',
	bowler: '\e833',
	all-rounder: '\e834',
	batter: '\e835',
);
$tabs: (
	commentary: '\e878',
	live-blog: '\e880',
	scorecard: '\e87b',
	graphs:'\e879',
	match-info:'\e817',
	teams: '\e96c',
);