@use "../config/" as *;

.hide-breadcrumb .waf-breadcrumb {
  display: none;
}

.waf-breadcrumb {
  padding: calc(var(--header-height) + var(--space-6)) var(--space-3)
    var(--space-2);
  position: relative;
  &::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: var(--shape-head, 44rem);
    position: absolute;
    border-bottom: 0.4rem solid clr(primary-500);
    z-index: var(--z-patterns);
    @include background(
      clr(secondary-1000),
      "patterns/mobile/shape-head.png",
      right/cover
    );
  }
  .breadcrumb {
    @extend %pl-0;
    &-list {
      list-style-type: none;
      @extend %p-0;
    }
    &-item {
      display: inline;
      @extend %font-12;
      @extend %primary-500;
      &:last-child {
        @extend %neutral-50-8;
      }
      &:not(:last-child) {
        &::after {
          @extend %neutral-50;
          @include icon(chevron-right);
          @extend %px-1;
        }
      }
    }
  }
}
@include mq(col-md) {
  .waf-breadcrumb::before {
    @include background(
      clr(secondary-1000),
      "patterns/shape-head.png",
      right/cover
    );
  }
}
