@use "./map" as *;
// scss variables
$base-space: 0.5rem;
$max-space-count: 20; // maximum count variable generation
$default-line-height: 1.6;
$body-font-size: 1.4rem;
$body-font-weight: 400;
// media query variables
$small-mobile-min-width: 0px;
$small-mobile-max-width: 360px;
$medium-mobile-min-width: 576px;
$mobile-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 991px;
$desktop-min-width: 992px;
$large-desktop-min-width: 1366px;
$xl-desktop-min-width: 1600px;
// font variables
$font-primary: "Rubik", sans-serif;
$font-icon: "waf-font-icon";
//  version variables
$image-version: "1.9";
$font-version: "81567481";
// css variables
:root {
  @each $color, $varients in $colors {
    @each $varient, $hsl in $varients {
      $h: nth($hsl, 1);
      $s: nth($hsl, 2);
      $l: nth($hsl, 3);
      --#{$color}-#{$varient}: hsl(#{$h} #{$s} #{$l});
      --hsl-#{$color}-#{$varient}: #{$h} #{$s} #{$l};
    }
  }
  // non color variables
  --base-font-size: 62.5%;
  --container-max-width: 100%;
  --content-width: var(--window-inner-width);
  --vh: 1vh;
  --vw: 1vw;
  --window-inner-height: calc(var(--vh, 1vh) * 100);
  --window-inner-width: calc(var(--vw, 1vw) * 100);
  --container-white-space: calc((var(--window-inner-width) - var(--container-max-width)) / 2);
  // space variable
  @for $i from 1 through $max-space-count {
    --space-#{$i}: #{$base-space * $i};
    --space-#{$i}-neg: #{$base-space * $i * -1};
  }
  --space-0: 0;
  // use
  // --space-1 = 5px
  // --space-2 = 10px.... till --space-12 = 60px
  // this is according to base space variable
  --full-radius: 1rem;
  --half-radius: calc(var(--full-radius) / 2);
  --quarter-radius: 0.3rem;
  // **********this is just example for naming convention so please remove it and use it***********
  // height varible
  --header-height: 9rem;
  --input-height: 4.5rem;
  --logo-ratio: 1.64285714;
  --logo-height: 4.6rem;
  --logo-width: calc(var(--logo-height) / var(--logo-ratio));
  --social-module-height: 6rem;
  --standings-strip-height: calc(4rem + env(safe-area-inset-bottom));
  --standings-strip-width: 3.5rem;
  --table-header-height: 3.5rem;
  --table-row-height: 5.4rem;
  --standing-width: 100%;
  --static-banner-height: 5rem;
  --sticky-score-board-height: 8rem;
  --swiper-navigation-top-offset: 5rem;
  //width variable
  --country-width: 11rem;
  // zindex variable ********** assending order *******************
  --z-header: 3;
  --z-hamburger-btn: 4;
  --z-patterns: -1;
  --swiper-button-width: 3.3rem;
  --swiper-button-height: 3rem;
  --footer-middle-height: 7.8rem;
  --footer-bottom-height: 6rem;
  @each $name, $index in $zindex {
    --z-#{$name}: #{$index};
  }
  /* Z-index variables*/
  @for $z from 1 through 10 {
    --z-index#{$z}: #{$z};
    --z-index-#{$z}: -#{$z};
  }
  --scorestrip-height: 10rem;
}
@media (min-width: $tablet-min-width) {
  :root {
    --container-max-width: 72rem;
    --filter-height: 7rem;
    --header-height: 6.2rem;
    --standings-strip-height: 2.5rem;
    --logo-ratio: 1.60526316;
    --logo-height: 6.1rem;
    --logo-width: calc(var(--logo-height) / var(--logo-ratio));
    --standing-width: 37.5rem;
    --footer-middle-height: 5rem;
    --footer-bottom-height: 5rem;
  }
}
@media (min-width: $desktop-min-width) {
  :root {
    --container-max-width: 96rem;
  }
}
@media (min-width: $large-desktop-min-width) {
  :root {
    --container-max-width: 114rem;
  }
}
@media (min-width: $xl-desktop-min-width) {
  :root {
    --container-max-width: 140rem;
  }
}