@use './config/' as *;
@forward "./main-scss/";
@forward './swiper/swiper-bundle';
@forward './listing/listing-common';
@forward './listing/related-listing';
.waf-detail {
    &.waf-gallery-page {
        @extend %p-0;
        @extend %neutral-50;
                .article {
            &-head {
                // max-width: 80rem;
                @extend %mx-auto;
                @extend %pb-1;
                .title {
                    font-weight: 500;
                    @extend %font-18;
                    @extend %my-3;
                    @extend %neutral-50;
                    @extend %pb-0;
                    @include truncate(3, 18);
                }
                .meta {
                    @extend %font-10-pr;
                    @extend %neutral-50;
                }
                .head-wrap {
                    @extend %flex-n-c;
                }
            }
            &-meta {
                flex-wrap: wrap;
                @extend %p-0;
                @extend %gap-1;
                @extend %flex-n-fs;
                .meta {
                    @extend %relative;
                    @extend %font-12;
                    @extend %m-0;
                    &-date {
                        @extend %font-12;
                        &::before {
                            @include icon(calendar);
                            @extend %mr-1;
                        }
                    }
                    &-count {
                        @extend %d-none;
                    }
                }
            }
            &-gallery {
                &-list {
                    display: grid;
                    gap: var(--space-4);
                    grid-template-columns: repeat(2, 1fr);
                }
                &-item {
                    position: relative;
                    grid-row: span 2;
                    @extend %article-item-scale;
                    &:nth-child(3n + 1) {
                        grid-column: span 2;
                    }
                    .img-box {
                        height: 100%;
                        border-radius: var(--half-radius);
                        img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .btn-more {
                        cursor: pointer;
                        @extend %w-100;
                        @extend %h-100;
                        @extend %pos-tl;
                    }
                }
            }
            &-body {
                // max-width: 80rem;
                position: relative;
                @extend %py-4;
                @extend %mx-auto;
            }
        }
        .reaction-section {
            flex: 1;
            @extend %flex-sb-c;
            @extend %pl-2;
            @extend %gap-2;
        }
        .social-share {
            position: unset;
            @extend %ml-auto;
            .share-icon {
                display: none;
            }
            .social-icon {
                @extend %mx-0;
                @extend %neutral-50-bg-3;
            }
            &-wrap {
                top: unset;
                right: var(--space-3);
                display: block;
                .social-items{
                    background: unset;
                }
                .close {
                    display: none;
                }
            }
        }
        .swiper {
            &-scrollbar {
                display: none;
            }
            &-pagination {
                width: max-content;
                position: unset;
                @extend %neutral-50-5;
                @extend %font-14;
                &-current {
                    @extend %font-32-pb;
                    @extend %neutral-50;
                }
            }
        }
        .download {
            width: max-content;
            .btn-site {
                @extend %primary-500-bg;
                @extend %p-1-2;
                @extend %half-radius
            }
            .btn-text {
                @extend %secondary-1100;
                @extend %flex-n-c;
                @extend %gap-2;
                @extend %font-12-pm;
                &::after {
                    @include icon(download)
                }
            }
        }
        .modal {
            &-window {
                .article {
                    &-title {
                        @extend %text-center;
                        @extend %neutral-50;
                        @extend %font-16-pm;
                    }
                }
                .item-type-icon,
                .article-description,
                .article-content {
                    display: none;
                }
                .social-share {
                    i {
                        &:before {
                            @extend %neutral-50;
                        }
                    }
                }
                .img-box {
                    img {
                        position: unset;
                    }
                }
            }
            &-body {
                @extend %flex-c-c;
                .preview-swiper {
                    height: 100%;
                    overflow: hidden;
                }
                .article-list {
                    @extend %flex;
                    .article-item {
                        flex-shrink: 0;
                        @extend %flex-c-c;
                    }
                    .article-wrap {
                        width: 100%;
                    }
                }
                .img-box {
                    border-radius: 0;
                }
            }
            &-footer {
                @extend %pb-6;
                @include flex-config(flex, row-reverse, space-between, flex-end);
            }
        }
    }
    .modal-window {
                @include background(null, "patterns/mobile/modal-bg.png", center/cover, linear-gradient(0deg, clr(secondary-1000) 25.82%, clr(secondary-900) 113.23%));
        border: 0;
        & > * {
            width: 100%;
        }
        .btn-close {
            position: fixed;
            .btn-text::before {
                font-size: 2rem;
                @extend %neutral-50;
            }
        }

        .modal {
            &-body {
                padding-inline: 0;
                background-color: transparent;
                max-height: 60%;
                margin-block: auto;
            }
            &-head {
                margin-top: 7rem;
                max-height: 20%;
            }
            &-footer {
                max-height: 20%;
                @extend %px-3;
            }
        }
        .reaction-section {
            flex-direction: column;
            align-items: flex-end;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-gallery-page .article-body::before,
    .waf-listing {
        @include background(null, "patterns/common-pattern.png", bottom/cover fixed);
    }
    .waf-detail {
        &.waf-gallery-page {
            .article-detail {
                padding-inline: var(--space-20);
            }
            .article-head {
                .title {
                    @include truncate(2, 24);
                }
            }
            .article-body {
                margin-top: 0;
                &::before {
                    inset: var(--space-2-neg) calc(((var(--window-inner-width) - 80rem)/2) * -1) 0;
                }
            }
            .article-gallery {
                &-list {
                    grid-template-columns: repeat(4, 1fr);
                }
                &-item {
                    flex-basis: unset;
                    width: unset;
                    margin-bottom: 0;
                    grid-row: span 2;
                    &:nth-child(3n + 1) {
                        grid-column: unset;
                    }
                    &:nth-child(8n + 2),
                    &:nth-child(8n + 4),
                    &:nth-child(8n + 5),
                    &:nth-child(8n + 6) {
                        grid-row: span 3;
                    }
                }
            }
            .modal {
                &-head {
                    padding-top: var(--space-8);
                    max-width: 80rem;
                    margin-inline: auto;
                }
                &-body {
                    height: auto;
                    max-width: unset;
                    width: 100%;
                    .swiper {
                        padding-inline: calc((var(--window-inner-width) - 80rem) / 2);
                        &-slide {
                            opacity: 0.7;
                            &-active {
                                opacity: 1;
                            }
                        }
                    }
                    .img-box {
                        background: transparent;
                        @include flex-config(flex, null, center, center);
                    }
                    img {
                        object-fit: cover;
                        // width: auto;
                        // height: 42rem;
                        border-radius: var(--half-radius);
                    }
                    .preview-swiper {
                        @include flex-config(flex, null, null, null);
                        width: 100%;
                    }
                }
                &-footer {
                    width: 100%;
                    max-width: 80rem;
                    margin-inline: auto;
                }
                &-window {
                    .article {
                        &-title {
                            font-size: 1.8rem;
                            margin-bottom: var(--space-4);
                        }
                    }
                }
            }
        }
        .modal-window {
            --swiper-navigation-sides-offset: calc((var(--window-inner-width) - 84rem)/2);
            @include background(null, "patterns/modal-bg.png", center/cover, linear-gradient(0deg, clr(secondary-1000) 25.82%, clr(secondary-900) 113.23%));
            .article-wrap,
            .article-thumbnail,
            .img-box,
            img {
                height: 100%;
            }
            .reaction-section {
                flex-direction: row;
                justify-content: flex-end;
            }
            .modal {
                &-head {
                    margin-top: 3rem;
                }
                &-body {
                    padding-block: 1rem;
                }
            }
        }
    }
}